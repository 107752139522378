<template>
  <div class="home">

    <DuneQuote :quote='quote'/>

    <v-container>
      <v-btn
        :disabled='quote_index == history[0]'
        color='primary'
        class='black--text'
        style='margin-right: 1rem;'
        @click="$emit('previous-quote')"
        >
        Previous
      </v-btn>

      <v-btn
        color='primary'
        class='black--text'
        @click="$emit('next-quote')"
        >
        Next
      </v-btn>
    </v-container>

  </div>
</template>

<script>
import DuneQuote from '@/components/DuneQuote.vue'

export default {

  name: 'HomeView',

  components: { DuneQuote },

  props: {
    history: { type: Array, default: () => [] },
    quote_index: { type: Number, default: 0 },
    quote: { type: Object, default: () => {} }
  }

}
</script>