<template>
  <v-app :style="{background: $vuetify.theme.themes[theme].background}">

    <v-app-bar app color='primary'>

      <v-toolbar-title
        class='black--text'
        style='cursor: pointer; font-family: "Dune-Rise"'
        @click="$router.push('/')">
        DAILY DUNE
      </v-toolbar-title>

      <v-spacer/>

      <v-switch
        inset
        color='white'
        prepend-icon='mdi-white-balance-sunny'
        style='margin-top: 1.5rem;'
        :input-value='$vuetify.theme.dark'
        @change='toggle_dark_mode()'
      />

      <v-menu offset-y v-if='$vuetify.breakpoint.width < mobile_breakpoint'>
        <template v-slot:activator='{ on, attrs }'>
          <v-btn plain v-bind='attrs' v-on='on'><v-app-bar-nav-icon/></v-btn>
        </template>
        <v-list>
          <v-list-item>
            <v-btn plain to='/c'>Collections</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn plain to='/t'>Tags</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn plain to='/a'>About</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn plain to='/s'>Support</v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn plain @click='show_help=true'>Help</v-btn>
          </v-list-item>
        </v-list>
      </v-menu>

      <div v-if='$vuetify.breakpoint.width >= mobile_breakpoint'>
        <v-btn plain to='/c'>Collections</v-btn>
        <v-btn plain to='/t'>Tags</v-btn>
        <v-btn plain to='/a'>About</v-btn>
        <v-btn plain to='/s'>Support</v-btn>
        <v-btn plain @click='show_help=true'>Help</v-btn>
      </div>

    </v-app-bar>

    <v-main class='main'>
      <router-view
        :collections='collections'
        :history='history'
        :tags='tags'
        :quote_index='quote_index'
        :quote='current_quote'
        :quotes='quotes'
        @next-quote='next_quote'
        @previous-quote='previous_quote'
        >
      </router-view>

      <help-dialog :show='show_help' @close-dialog='show_help=false'/>

    </v-main>

  <v-footer padless>
     <v-col class='text-center' cols='12'>
        © {{ new Date().getFullYear() }} Daily Dune
        <router-link :to="'/changelog'">
          v{{ version }}
        </router-link>
     </v-col>
  </v-footer>

  </v-app>
</template>

<script>
import HelpDialog from './components/HelpDialog.vue'

export default {

  components: { HelpDialog },

  name: 'App',

  data() {
    return {
      current_quote: null,
      history: [],
      mobile_breakpoint: 650,
      quote_index: 0,
      quotes: null,
      show_help: false,
      unread: [],
      version: '0.5.0'
    }
  },

  created() {

    // Read theme value from localStorage if present, otherwise set it
    if (window.localStorage.getItem('theme')) {
      if (window.localStorage.getItem('theme') == 'dark') {
        this.$vuetify.theme.dark = true
      } else {
        this.$vuetify.theme.dark = false
      }
    } else {
      window.localStorage.setItem('theme', this.theme)
    }

    this.quotes = require('../data/quotes.json'),
    this.unread = [...Array(this.quotes.length).keys()]
    this.new_quote()

  },

  mounted() {
    window.addEventListener('keypress', e => {
      if (e.key == 'a') {this.$router.push('/a')}
      if (e.key == 'b') {this.previous_quote()}
      if (e.key == 'c') {this.$router.push('/c')}
      if (e.key == 'd') {this.toggle_dark_mode()}
      if (e.key == 'h') {this.show_help = true}
      if (e.key == 'n') {this.next_quote()}
      if (e.key == 'q') {this.$router.push('/')}
      if (e.key == 'r') {this.new_quote()}
      if (e.key == 't') {this.$router.push('/t')}
    })
  },

  methods: {

    new_quote() {
      // Randomly pick a new quote and add it to the history

      // If we are out of unread quotes, re-populate the array
      if (this.unread.length == 0) {
        this.unread = [...Array(this.quotes.length).keys()]
      }

      // Pick a random quote index from an array of unread quote indices
      this.quote_index = this.unread[Math.floor(Math.random() * this.unread.length)]

      // Set the current quote; this will update the content in the DuneQuote component
      this.current_quote = this.quotes[this.quote_index]

      // Add this quote index to the history
      this.history.push(this.quote_index)

      // Remove this quote index from the array of unread quote indices
      this.unread.splice(this.unread.indexOf(this.quote_index), 1)

      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    },

    next_quote() {
      // Switch to next quote in history
      if (this.quote_index == this.history[this.history.length - 1]) {
        this.new_quote()
      } else {
        this.quote_index = this.history[this.history.indexOf(this.quote_index) + 1]
        this.current_quote = this.quotes[this.quote_index]
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    },

    previous_quote() {
      // Switch to previous quote in history
      if (this.quote_index != this.history[0]) {
        this.quote_index = this.history[this.history.indexOf(this.quote_index) - 1]
        this.current_quote = this.quotes[this.quote_index]
        window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
      }
    },

    toggle_dark_mode() {
      // Toggles dark/light mode
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark
      window.localStorage.setItem('theme', this.theme)
    }

  },

  computed: {

    collections() {
      // Returns a unique and sorted list of all collections in the quotes dataset
      return [...new Set([].concat.apply([], this.quotes.map(a => a.collection)))]
             .sort()
             .filter(x => x != undefined)
    },

    tags() {
      // Returns a unique and sorted list of all tags in the quotes dataset
      return [...new Set([].concat.apply([], this.quotes.map(a => a.tags)))]
             .sort()
             .filter(x => x != undefined)
    },

    theme() {
      // Helper property for toggling the background color
      // in support of dark mode
      return (this.$vuetify.theme.dark) ? 'dark' : 'light'
    }

  },

  watch: {
    $route () {
      window.scrollTo({top: 0, left: 0, behavior: 'smooth'})
    }
  }

}
</script>


<style scoped>

@font-face {
  font-family: 'Dune-Rise';
  src: local('Merienda'), url(./fonts/Dune-Rise.otf) format('truetype');
}

</style>
