import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import colors from 'vuetify/lib/util/colors'

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {

      light: {
        primary: colors.amber.accent3,
        anchor: colors.blue.base,
        background: colors.yellow.lighten4
      },

      dark: {
        primary: colors.indigo.accent1,
        background: colors.blueGrey.darken4
      },

    },
  },
});
