<template>
  <div id='help-dialog'>

    <v-dialog
      v-model='show_dialog'
      width='80%'
      @click:outside='close()'
      @keydown.esc='close()'
    >
      <v-card elevation=24>
        
        <v-container>
          <h1>Help</h1>

          <p>You can use the following key strokes to navigate Daily Dune:</p>

          <div><kbd>h</kbd>: Show this help window</div>
          <div><kbd>r</kbd>: Random new quote</div>
          <div><kbd>n</kbd>: Next quote</div>
          <div><kbd>b</kbd>: Back (previous quote)</div>
          <div><kbd>q</kbd>: Go to the Quotes (home) page</div>
          <div><kbd>c</kbd>: Go to the Collections page</div>
          <div><kbd>t</kbd>: Go to the Tags page</div>
          <div><kbd>a</kbd>: Go to the About page</div>
          <div><kbd>d</kbd>: Toggle dark/light mode</div>
          <div><kbd>Esc</kbd>: Close this window</div>

        </v-container>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color='primary'
            text
            @click='close()'
          >
            Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>

export default {

  name: 'HelpDialog',

  props: {
    show: {type: Boolean, default: false}
  },

  data() {
    return {
      show_dialog: false,
    }
  },

  methods: {

    close() {
      // Closes the pop-up dialog.
      this.show_dialog = false
      this.$emit('close-dialog')
    }

  },

  watch: {
    
    show() {
      // Watch when the prop changes value.
      // This is needed because we can mutate the prop directly,
      // so we're using a data variable to control within this
      // component whether the pop-up should render.
      this.show_dialog = this.show
    }

  }

}
</script>