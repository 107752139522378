import Vue from 'vue'
import VueRouter from 'vue-router'
import AboutView from '../views/AboutView.vue'
import ChangeLogView from '../views/ChangeLogView.vue'
import CollectionView from '../views/CollectionView.vue'
import CollectionsView from '../views/CollectionsView.vue'
import HomeView from '../views/HomeView.vue'
import SupportView from '../views/SupportView.vue'
import TagsView from '../views/TagsView.vue'
import TagView from '../views/TagView.vue'

Vue.use(VueRouter)

const routes = [

  {
    path: '/',
    name: 'home',
    component: HomeView,
    props: true
  },

  {
    path: '/c',
    name: 'collections',
    component: CollectionsView,
    props: true
  },

  {
    path: '/c/:collection',
    name: 'collection',
    component: CollectionView,
    props: true
  },

  {
    path: '/t',
    name: 'tags',
    component: TagsView,
    props: true
  },

  {
    path: '/t/:tag',
    name: 'tag',
    component: TagView,
    props: true
  },

  {
    path: '/a',
    name: 'about',
    component: AboutView,
    props: true
  },

  {
    path: '/s',
    name: 'support',
    component: SupportView,
    props: false
  },

  {
    path: '/changelog',
    name: 'changelog',
    component: ChangeLogView,
    props: false
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
