<template>
  <div>

    <v-container>
      <h1>Collections</h1>
    </v-container>

    <v-container>
      <li v-for='collection in collections' :key='collection'>
        <router-link :to="'/c/' + to_url(collection)">
          {{ collection }}
        </router-link>
      </li>
    </v-container>

  </div>
</template>

<script>
import to_lowercase_with_hyphens from '@/util'

export default {

  name: 'CollectionsView',

  data() {
    return {
      to_url: to_lowercase_with_hyphens
    }
  },

  props: {
    collections: {
      type: Array,
      default: () => {}
    }
  }

}
</script>
