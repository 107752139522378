<template>
  <div>

    <v-container>
      <h1>{{ tag_name }}</h1>
    </v-container>

    <div v-for='quote in quotes_by_tag' :key='quote.text'>
      <DuneQuote :quote='quote'/>
      <v-divider/>
    </div>

  </div>
</template>

<script>
import DuneQuote from '@/components/DuneQuote.vue'
import to_lowercase_with_hyphens from '@/util'

export default {

  name: 'TagView',

  components: {
    DuneQuote
  },

  data() {
    return {
      to_url: to_lowercase_with_hyphens
    }
  },

  props: {
    quotes: {
      type: Array,
      default: () => []
    }
  },

  computed: {

    tags() {
      // Returns a unique and sorted list of all tags in the quotes dataset
      return [...new Set([].concat.apply([], this.quotes.map(a => a.tags)))]
             .sort()
             .filter(x => x != undefined)
    },

    tag_name() {
      return this.tags
                 .find(x => this.to_url(x) == this.$route.params.tag)
    },

    quotes_by_tag() {
      return this.quotes
                 .filter(quote => quote.tags
                                       .map(tag => this.to_url(tag, 3))
                                       .includes(this.$route.params.tag))
    }

  }

}
</script>
