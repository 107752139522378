<template>
  <div>

    <v-container>
      <h1>{{ collection_name }}</h1>
    </v-container>

    <div v-for='quote in quotes_by_collection' :key='quote.text'>
      <DuneQuote :quote='quote'/>
      <v-divider/>
    </div>

  </div>
</template>

<script>
import DuneQuote from '@/components/DuneQuote.vue'
import to_lowercase_with_hyphens from '@/util'

export default {

  name: 'CollectionView',

  components: {
    DuneQuote
  },

  data() {
    return {
      to_url: to_lowercase_with_hyphens
    }
  },

  props: {
    quotes: {
      type: Array,
      default: () => []
    }
  },

  computed: {

    collections() {
      // Returns a unique and sorted list of all collections in the quotes dataset
      return [...new Set([].concat.apply([], this.quotes.map(a => a.collection)))]
             .sort()
             .filter(x => x != undefined)
    },

    collection_name() {
      return this.collections
                 .find(x => this.to_url(x) == this.$route.params.collection)
    },

    quotes_by_collection() {
      return this.quotes
                 .filter(quote => quote.collection !== undefined)
                 .filter(
                   quote => this.to_url(quote.collection) == this.$route.params.collection
                 )
    }

  }

}
</script>
