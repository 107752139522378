<template>
  <div class="about">

    <v-container>
      <v-row justify='center' class='about-title'>
        <h1>DAILY DUNE</h1>
      </v-row>
      <v-row justify='center' style='margin: 10px'>
        <v-col class='text-right' cols='1'>
          <v-icon>mdi-white-balance-sunny</v-icon>
        </v-col>
        <v-col class='text-center' cols='1'>
          <v-icon>mdi-star-four-points</v-icon>
        </v-col>
        <v-col class='text-left' cols='1'>
          <v-icon>mdi-moon-waxing-crescent</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <v-container>
      <v-row justify='center'>
        <v-img
          src='../assets/paul_512x512.jpg'
          max-width='300'
        />
      </v-row>
    </v-container>

    <v-container class='about-text'>
      <p>
      Daily Dune brings you nuggets of wisdom from the fascinating world of
      Frank Herbert's Dune.
      I made it because I couldn't find an easy-to-use and quick-to-browse
      Dune quotes reader.
      It currently has {{ quotes.length }} quotes across
      <router-link :to="'/c'">
        {{ collections.length }} collections
      </router-link> and
      <router-link :to="'/t'">{{ tags.length }} tags</router-link>.
      </p>

      <p>
      Daily Dune is a work in progress.
      It currently covers the entire first book and I will soon start working
      on the quotes from Dune Messiah.
      All quotes are © Herbert Properties LLC and provided here under fair use.
      Artwork is by
      <a href='https://www.instagram.com/fearei.production'>FeaRei</a>
      and used under permission.
      </p>

      <p>
      If you're enjoying Daily Dune, please see how to
      <router-link :to="'/s'">support it</router-link>.
      To reach out, email me at
      <a href='mailto:milancurcic@hey.com'>milancurcic@hey.com</a>,
      of find my other projects at
      <a href='https://milancurcic.com'>milancurcic.com</a>.
      </p>

    </v-container>

  </div>
</template>


<script>
export default {

  name: 'AboutView',

  props: {
    quotes: {type: Array, default: () => []},
    collections: {type: Array, default: () => []},
    tags: {type: Array, default: () => []},
  }

}
</script>


<style scoped>
.about-text {
  font-family: Garamond, serif;
  font-size: x-large;
  padding: 20px;
}

.about-title {
  font-family: 'Dune-Rise';
  padding-top: 50px;
}
</style>
