<template>
  <div class='changelog'>

    <v-container class='changelog-title'>
      <h1>Changelog</h1>
    </v-container>

    <v-container class='changelog-text'>
      <ul>
        <li v-for='log in changelog' :key='log.version'>
          {{ log.version }} ({{ log.date }}):
          <ul>
            <li v-for='item in log.items' :key='item'>
              {{ item }}
            </li>
          </ul>
        </li>
      </ul>
    </v-container>

  </div>
</template>


<script>
export default {

  name: 'ChangeLogView',

  data() {
    return {
      changelog: [
        {
          version: '0.5.0',
          date: '2022-07-17',
          items: [
            'Add the support page'
          ]
        },
        {
          version: '0.4.2',
          date: '2022-03-18',
          items: [
            'Hyphenate and lowercase tag and collection URLs'
          ]
        },
        {
          version: '0.4.1',
          date: '2022-02-03',
          items: [
            'Fix title and meta tags',
            'Add social preview tags',
          ]
        },
        {
          version: '0.4.0',
          date: '2022-01-27',
          items: [
            'Add the changelog page'
          ]
        },
        {
          version: '0.3.0',
          date: '2022-01-24',
          items: [
            'Add the Paul Atreides artwork by FeaRei for icons and the about page',
            'Use the Dune-Rise font for the title in the navbar and the about page'
          ]
        },
        {
          version: '0.2.0',
          date: '2022-01-17',
          items: [
            'Preserve the light/dark mode between sessions',
            'Show the version number in the footer'
          ]
        },
        {
          version: '0.1.0',
          date: '2022-01-05',
          items: [
            'First release'
          ]
        }
      ]
    }
  }

}
</script>


<style scoped>
.changelog-text {
  font-family: Garamond, serif;
  font-size: x-large;
  padding: 20px;
}
</style>
