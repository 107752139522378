<template>
  <div class="about">

    <v-container>
      <v-row justify='center' class='about-title'>
        <h1>DAILY DUNE</h1>
      </v-row>
      <v-row justify='center' style='margin: 10px'>
        <v-col class='text-right' cols='1'>
          <v-icon>mdi-white-balance-sunny</v-icon>
        </v-col>
        <v-col class='text-center' cols='1'>
          <v-icon>mdi-star-four-points</v-icon>
        </v-col>
        <v-col class='text-left' cols='1'>
          <v-icon>mdi-moon-waxing-crescent</v-icon>
        </v-col>
      </v-row>
    </v-container>

    <v-container class='about-text'>
      <p>
      If you're enjoying Daily Dune and would like to support its development,
      please consider purchasing Dune novels, movies, and/or board games using
      one of the links below.
      </p>
    </v-container>

    <v-container>
      <v-row justify='center'>
        <a href="https://www.amazon.com/Frank-Herberts-Dune-6-Book-Boxed/dp/0593201884?_encoding=UTF8&qid=&sr=&linkCode=li3&tag=dailydune-20&linkId=85fff37d4c0b9cb2f6c72d17d820410a&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=0593201884&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dailydune-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dailydune-20&language=en_US&l=li3&o=1&a=0593201884" width="1" height="1" border="0" alt="" style="border:none !important; margin:15px !important;" />
        <a href="https://www.amazon.com/Dune-Blu-ray-Digital-Frank-Herbert/dp/B09MDHD2TP?keywords=dune+movie&qid=1658111339&sprefix=dune+mo%2Caps%2C121&sr=8-3&linkCode=li3&tag=dailydune-20&linkId=0c5220efa9998f726ef95dd0852a0c7e&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B09MDHD2TP&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dailydune-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dailydune-20&language=en_US&l=li3&o=1&a=B09MDHD2TP" width="1" height="1" border="0" alt="" style="border:none; margin:15px;" />
        <a href="https://www.amazon.com/Gale-Force-Nine-DUNE01BFM-Board/dp/1947494503?keywords=dune+board+game&qid=1658111205&s=toys-and-games&sprefix=dune+board%2Ctoys-and-games%2C139&sr=1-2&linkCode=li3&tag=dailydune-20&linkId=93f516e1023f7daeb2ad9fc1b0cb24cd&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=1947494503&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dailydune-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dailydune-20&language=en_US&l=li3&o=1&a=1947494503" width="1" height="1" border="0" alt="" style="border:none; margin:15px;" />
        <a href="https://www.amazon.com/Dire-Wolf-DWD01000-Dune-Imperium/dp/B08JHB9V72?keywords=dune+board+game&qid=1658111260&s=toys-and-games&sprefix=dune+board%2Ctoys-and-games%2C139&sr=1-4&linkCode=li3&tag=dailydune-20&linkId=1be5fab84dd579758de3e58c36a18e61&language=en_US&ref_=as_li_ss_il" target="_blank"><img border="0" src="//ws-na.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08JHB9V72&Format=_SL250_&ID=AsinImage&MarketPlace=US&ServiceVersion=20070822&WS=1&tag=dailydune-20&language=en_US" ></a><img src="https://ir-na.amazon-adsystem.com/e/ir?t=dailydune-20&language=en_US&l=li3&o=1&a=B08JHB9V72" width="1" height="1" border="0" alt="" style="border:none; margin:15px;" />
      </v-row>
    </v-container>

  </div>
</template>


<script>
export default {

  name: 'SupportView',

}
</script>


<style scoped>
.about-text {
  font-family: Garamond, serif;
  font-size: x-large;
  padding: 20px;
}

.about-title {
  font-family: 'Dune-Rise';
  padding-top: 50px;
}
</style>
