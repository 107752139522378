<template>
  <div>

    <v-container>
      <h1>Tags</h1>
    </v-container>

    <v-container>
      <li v-for='tag in tags' :key='tag'>
        <router-link :to="'/t/' + to_url(tag, 3)">{{ tag }}</router-link>
      </li>
    </v-container>

  </div>
</template>

<script>
import to_lowercase_with_hyphens from '@/util'

export default {

  name: 'TagsView',

  data() {
    return {
      to_url: to_lowercase_with_hyphens
    }
  },

  props: {
    tags: {
      type: Array,
      default: () => {}
    },
  }

}
</script>
