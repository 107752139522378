<template>
  <div>

    <v-container class='quote-text' v-html='quote.text'>
      {{ quote.text }}
    </v-container>

    <v-container class='font-italic align-right'>
      —{{ quote.source }}
    </v-container>

    <v-container class='align-right'>
      Tags: 
      <span v-for='(tag, index) in quote.tags' :key=tag>
        <span v-if='index != 0'>, </span>
        <router-link :to="'/t/' + to_url(tag, 3)">{{ tag }}</router-link>
      </span>
    </v-container>

  </div>
</template>

<script>
import to_lowercase_with_hyphens from '@/util'

export default {

  name: 'DuneQuote',

  data() {
    return {
      to_url: to_lowercase_with_hyphens
    }
  },

  props: {
    quote: {
      type: Object,
      default: () => {}
    }
  }

}
</script>

<style scoped>
.align-right {
  text-align: right;
}

.quote-text {
  font-family: Garamond, serif;
  font-size: x-large;
  padding: 50px 20px 10px 20px;
}
</style>
